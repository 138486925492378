<template>
	<div class="xguang">
		<el-row>
			<el-col :span="12" style="text-align: center;">
				<h3>上传X光片</h3>
				<div>您可以在<a href="#">此处</a>找到有关图片的示例。(点击后跳转出示例文件页)</div>
				<div style="width: 70%;margin: 0px auto;" >
					<el-form :model="ruleForm"  ref="ruleForm">
						<el-form-item prop="quanjingDate" label="全景片成像日期" >
						  <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.quanjingDate" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>		     
						</el-form-item>
						<el-form-item prop="ceweiDate" label="头颅侧位成像日期" >
						  <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.ceweiDate" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>		     
						</el-form-item>
					</el-form>
				</div>
				<div>注意：图像需近30天内。</div>
				<div>有效格式：.jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff</div>
				<div>最小文件大小：100KB</div>
				
			</el-col>
			<el-col :span="12" style="text-align: left;padding-left: 30px;padding-top: 0.625rem;">
				<div style="font-size: 0.875rem;">请上传全景片</div>
				<el-upload
				  class="upload-demo"
				  drag
				  action=''
				  ref="upload"
				  :http-request=changeFile
				  multiple
				  accept=".jpg,.jpeg,.dcm,.bmp,.tif"
				  :before-upload="beforeAvatarUpload"
				  >
				  <span class="qj"></span><br />
				  <i class="el-icon-upload"></i>
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip"></div>
				</el-upload>
				<!-- 请上传头颅侧位片 -->
				<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传头颅侧位片</div>
				<el-upload
				  class="upload-demo1"
				  drag
				  action=''
				  multiple
				  accept=".jpg,.png,.jpeg,.dcm,.bmp,.tif"
				  :http-request=changeFile1
				  >
				  <span class="cw"></span><br />
				  <i class="el-icon-upload"></i>
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip"></div>
				</el-upload>
				<button @click="goOrderTwo" class="btnNextKouSao" style="width: 30%;" type="button">返回</button>
				<button @click="nextStepKS" class="btnNextKouSao" style="width: 30%;" type="button">下一步</button>
			</el-col>
		</el-row>

	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				ruleForm:{
					quanjingDate:'',
					ceweiDate:'',
					oid:'0'
				},
				
				
			}
		},
		methods:{
			    beforeAvatarUpload(file) {
			      console.log('file', file)
			      let types = ['image/jpeg', 'image/jpg', 'image/png'];
			      const isImage = types.includes(file.type);
			      if (!isImage) {
			        this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			      }else{
			        //this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			      }
				  const isLtSize = file.size / 1024 / 1024 < 5;
				   if (!isLtSize) {
				            this.$message.error('上传图片大小不能超过 1MB!');
				       }
			    },
				//上传全景图
			changeFile(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'quanjing')
					  console.log(formData.get('type'));
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		console.log(res.data);
						if(res.data.code==200){
							this.$message.success("上传成功");
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//侧位图
			changeFile1(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'cewei')
					  console.log(formData.get('type'));
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		console.log(res.data);
						if(res.data.code==200){
							this.$message.success("上传成功");
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//下一步
			nextStepKS(){
				//上传两个日期
				let result1;
				let result2;
				 let formData1 = new FormData()
				formData1.append('date',this.ruleForm.quanjingDate)// 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type', 'qjDate')
				
				this.$axios.post("client/order/uploadResourceDate",formData1,{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					console.log(res);
					result1=res.data.code;
				}).catch(err=>{
					this.$message.error("添加失败");
				});
				//头颅侧位
				
				let formData2 = new FormData()
				console.log(this.ruleForm.ceweiDate);
				formData2.append('date',this.ruleForm.ceweiDate)// 传文件
				formData2.append('oid', this.ruleForm.oid)
				formData2.append('type', 'cwDate')
				
				this.$axios.post("client/order/uploadResourceDate",formData2,{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					console.log(res);
					result2=res.data.code;
					this.$router.push("orderfour");
				}).catch(err=>{
					this.$message.error("添加失败");
				});
				
			},
			//返回
			goOrderTwo(){
				this.$router.push("/addorder/ordertwo")
			}
		
		},
		mounted:function(){
			this.$store.commit('setStepIndex', 2);
			//设置默认地址
			sessionStorage.setItem("location","orderthree")
			//获取当前添加的订单oid
			//获取oid
			let oid=sessionStorage.getItem("oid");
			if(oid==null||oid=="null"){
				this.$alert("请填写患者信息","提示");
				this.$router.push("/addorder/orderone");
			}else{
				this.ruleForm.oid=oid;
			}
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
		
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
			}
			this.$axios.get("/client/order/getOrderInfo",{
				params:{
					oid:this.ruleForm.oid
				}
			}).then(res=>{
			
				let qjDate=res.data.data.resource.qjDate;
				let cwDate=res.data.data.resource.cwDate;
				let cewei=res.data.data.resource.cewei;
				let quanjing=res.data.data.resource.quanjing;
				//全景日期回显
				if(qjDate!=null){
					this.ruleForm.quanjingDate=qjDate;
				}
				//侧位日期回显
				if(cwDate!=null){
					this.ruleForm.ceweiDate=cwDate;
				}
				//回显全景图片
				if(quanjing!=null){
					document.getElementsByClassName("qj")[0].innerHTML="全景图片已上传"
					document.getElementsByClassName("qj")[0].style.color="#2B9BB3";
					document.getElementsByClassName("qj")[0].style.fontWeight="bolder";
				}
				//回显侧位图片
				if(cewei!=null){
					document.getElementsByClassName("cw")[0].innerHTML="侧位图片已上传"
					document.getElementsByClassName("cw")[0].style.color="#2B9BB3";
					document.getElementsByClassName("cw")[0].style.fontWeight="bolder";
				}
			}).catch(err=>{
				console.log(err);
			});
		}
	}
</script>

<style scoped="scoped">
	.xguang{
	background-image: linear-gradient(to bottom,white, #9BEDEB, #28D9D5);
		height: 90%;
	}
	.btnNextKouSao{
		width: 60%;
		height: 1.875rem;
		border-radius: 0.625rem;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border: none;
	}
</style>
